<div class="mdm-wrapper-less">
  <div class="zs-master-style">
    <section>
      <main>
        <app-toaster-service></app-toaster-service>
        <div class="master-data-header-div" *ngIf="!(activeTab === MDMWrapperConstants.ALL_RECORDS && isProfile)">
          <span class="master-style-span">
            <!-- <h2 class="mdm-heading" translate>{{uiLabels.MASTER_DATA_MANAGEMENT}}</h2> -->
          </span>
          <span *ngIf="activeTab === MDMWrapperConstants.SUSPECTED_MATCHES" class="record-statistics"><button
              class="zs-button zs-button-solid zs-border-rounded-0 zs-margin-1-2-0-0"
              (click)="showViewRecordsStatisticsPopup()" translate>{{uiLabels.VIEW_RECORD_STATISTICS}}</button></span>
        </div>
        <section [ngClass]="{'zs-padding-0-2-2-2': (activeTab !== MDMWrapperConstants.ALL_RECORDS || !isProfile)}">
          <div class="zs-grid-row">
          </div>
          <div data-page="tabs">
            <zs-tabs-container *ngIf="!(activeTab === MDMWrapperConstants.ALL_RECORDS && isProfile)"
              class="zs-tabs zs-tabs-solid">
              <zs-tab class="zs-tab" [targetId]="MDMWrapperConstants.SUSPECTED_MATCHES"
                [attr.active]="activeTab === MDMWrapperConstants.SUSPECTED_MATCHES ? true : null"
                (click)="setSelectedTab(MDMWrapperConstants.SUSPECTED_MATCHES)" translate>
                {{uiLabels.SUSPECTED_MATCHES}}
              </zs-tab>
              <zs-tab class="zs-tab" [targetId]="MDMWrapperConstants.ALL_RECORDS"
                [attr.active]="activeTab === MDMWrapperConstants.ALL_RECORDS ? true : null"
                (click)="setSelectedTab(MDMWrapperConstants.ALL_RECORDS)" translate>{{uiLabels.ALL_RECORDS}}
              </zs-tab>
              <zs-tab *ngIf="this.environment.isMDMAdvancedSearchEnabled" class="zs-tab"
                [targetId]="MDMWrapperConstants.ADVANCED_SEARCH"
                [attr.active]="activeTab === MDMWrapperConstants.ADVANCED_SEARCH ? true : null"
                (click)="setSelectedTab(MDMWrapperConstants.ADVANCED_SEARCH)" translate>{{uiLabels.ADVANCED_SEARCH}}
              </zs-tab>
              <zs-tab class="zs-tab" [targetId]="MDMWrapperConstants.DCR_MANAGEMENT"
                [attr.active]="activeTab === MDMWrapperConstants.DCR_MANAGEMENT ? true : null"
                [targetId]="MDMWrapperConstants.DCR_MANAGEMENT"
                *ngIf="IsVisible.DCR_Management"
                (click)="setSelectedTab(MDMWrapperConstants.DCR_MANAGEMENT)" translate>DCR Management
              </zs-tab>
              <zs-tab class="zs-tab" [targetId]="MDMWrapperConstants.ACTIVITY_LOG"
                [attr.active]="activeTab === MDMWrapperConstants.ACTIVITY_LOG ? true : null"
                [targetId]="MDMWrapperConstants.ACTIVITY_LOG"
                *ngIf="IsVisible.Activity_Log"
                (click)="setSelectedTab(MDMWrapperConstants.ACTIVITY_LOG)" translate>{{uiLabels.ACTIVITY_LOG}}
              </zs-tab>
              <zs-tab class="zs-tab" [targetId]="MDMWrapperConstants.ADMIN" *ngIf="isAdmin"
                [attr.active]="activeTab === MDMWrapperConstants.ADMIN ? true : null"
                (click)="setSelectedTab(MDMWrapperConstants.ADMIN)" translate>Admin
              </zs-tab>
            </zs-tabs-container>
            <div [attr.source-id]="MDMWrapperConstants.SUSPECTED_MATCHES" active
              [attr.active]="activeTab === MDMWrapperConstants.SUSPECTED_MATCHES ? true : null"
              [ngStyle]="activeTab === MDMWrapperConstants.SUSPECTED_MATCHES && {} || {'display': 'none'}">
              <app-dashboard class="width" (selectedFilterChange)="selectedFilterChanged($event)"
                *ngIf="activeTab === MDMWrapperConstants.SUSPECTED_MATCHES"></app-dashboard>
            </div>
            <div [attr.source-id]="MDMWrapperConstants.ALL_RECORDS"
              [attr.active]="activeTab === MDMWrapperConstants.ALL_RECORDS ? true : null"
              [ngStyle]="activeTab === MDMWrapperConstants.ALL_RECORDS && {} || {'display': 'none'}">
              <mdm-search class="width" *ngIf="activeTab === MDMWrapperConstants.ALL_RECORDS && !isProfile">
              </mdm-search>
              <mdm-profile class="width" *ngIf="activeTab === MDMWrapperConstants.ALL_RECORDS && isProfile">
              </mdm-profile>
            </div>
            <div [attr.source-id]="MDMWrapperConstants.ADVANCED_SEARCH"
              [attr.active]="activeTab === MDMWrapperConstants.ADVANCED_SEARCH ? true : null"
              [ngStyle]="activeTab === MDMWrapperConstants.ADVANCED_SEARCH && {} || {'display': 'none'}"
              *ngIf="this.environment.isMDMAdvancedSearchEnabled">
              <mdm-compare class="width" *ngIf="activeTab === MDMWrapperConstants.ADVANCED_SEARCH"></mdm-compare>
            </div>
            <div [attr.source-id]="MDMWrapperConstants.DCR_MANAGEMENT">
              <app-dcr-management *ngIf='activeTab === MDMWrapperConstants.DCR_MANAGEMENT' class="width">
              </app-dcr-management>
            </div>
            <div [attr.source-id]="MDMWrapperConstants.ACTIVITY_LOG">
              <mdm-activity-log *ngIf='activeTab === MDMWrapperConstants.ACTIVITY_LOG' class="width"></mdm-activity-log>
            </div>

            <div [attr.source-id]="MDMWrapperConstants.ADMIN">
              <app-mdm-config-details *ngIf='activeTab === MDMWrapperConstants.ADMIN' class="width">
              </app-mdm-config-details>
            </div>
          </div>
        </section>
      </main>
    </section>
    <app-modaldialog class="record-statistics-popup" [(showmodal)]="showViewRecordsStatistics"
      [titletext]="recordStatisticsLabel" [isCrossIconEnabled]='true' [customstyle]="{
        'max-height': '90%',
        'min-height': '50%',
        overflow: 'auto',
        width: '88.7%'
      }">
      <section modal-body>
        <form id="record-statistics" class="zs-form">
          <div>
            <app-stats [selectedFilter]="selectedFilter"></app-stats>
          </div>
        </form>
      </section>
      <div modal-footer>
        <button class="zs-button zs-button-solid zs-border-rounded-0" (click)="hideViewRecordsStatisticsPopup()"
          translate>{{ uiCommonLabels.CANCEL }}</button>
      </div>
    </app-modaldialog>
  </div>
</div>